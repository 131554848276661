/* src/components/Navbar.css */

.navbar {
    position: fixed; /* Fix the navbar to the top */
    top: 0;          /* Align it to the top of the screen */
    width: 95%;     /* Make sure it spans the full width */
    z-index: 1001;   /* Ensure it appears above other content */
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: black; /* Background color */
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    gap: 30px;
  }
  
  .nav-links li {
    font-weight: bold; /* Make text bold */
    cursor: pointer;
  }
  
  .nav-links li a {
    color: white; /* Make the text color white */
    text-decoration: none; /* Remove underline */
  }
  
  .nav-links li a:hover {
    text-decoration: none; /* Ensure underline doesn't appear on hover */
  }
  