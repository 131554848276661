/* src/components/Projects.css */

.projects-section {
    height: 70vh;
    background-color: black;
    color: white;
    text-align: center;
  }

.projects-header{
    font-size: 2rem;;
}
  
  .projects-carousel {
    position: relative;
    width: 500px; /* Adjust to fit your design */
    margin: 0 auto;
    height: 350px; /* Make the card more rectangular */
  }
  
  .project-card {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #222;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.8s ease, opacity 0.8s ease;
    opacity: 0;
    transform: scale(0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow for a raised effect */
  }
  
  .project-card.active {
    opacity: 1;
    transform: translateX(0) scale(1);
    z-index: 3;
  }
  
  .project-card.shuffling {
    transform: translateX(-90%) rotate(-15deg) scale(0.9); /* Move left, rotate slightly, and shrink */
    z-index: 0; /* Send to the back */
  }
  
  .project-card:not(.active):not(.shuffling) {
    transform: translateX(30px) scale(0.9); /* Slightly offset inactive cards */
    opacity: 0.8; /* Slightly visible inactive cards */
    z-index: 2;
  }
  
  .project-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: -10px;
    bottom: 10px;
    background-color: #222;
    border-radius: 10px;
    z-index: -1;
    transform: scale(0.95); /* Removed translateX to prevent overlap */
  }
  
/* src/components/Projects.css */

/* Existing styles ... */

.project-link {
    position: absolute;
    top: 10px;
    right: 10px; /* Position in the top right corner */
    color: white;
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
    z-index: 4; /* Ensure it stays on top */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */
  }
  
  .project-link:hover {
    transform: scale(1.2); /* Grow the icon on hover */
    background-color: #333;
  }
  
  .project-link:active {
    transform: scale(1); /* Shrink slightly on click */
    background-color: #333;
  }
  
  
  .project-content {
    margin-top: 30px;
  }
  
  .project-title {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .project-description {
    margin: 10px 0;
  }
  
  .project-skills {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .skill-bubble {
    background-color: #444;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
    white-space: nowrap;
  }
  