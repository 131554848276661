/* src/components/GlowCursor.css */

.glow-cursor {
    position: fixed;
    width: 1000px; /* Adjust the size as needed */
    height: 1000px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 70%);
    border-radius: 50%;
    pointer-events: none; /* Make sure it doesn't block interactions */
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure it stays on top */
  }