/* src/components/Experience.css */

.experience-section {
  margin-top: 15vh;
  height: 80vh;
  background-color: black;
  color: white;
  text-align: center;
  padding: 40px 20px; /* Reduced padding */
}

.experience-section h2 {
  align-items: center;
  font-size: 2rem; /* Size of the section title */
}

.experience-container {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;     /* Centers content vertically */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}
  
  .experience-box {
    justify-content: center;
    background-color: #222;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    max-width: 30%;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
    box-sizing: border-box;
    text-decoration: none; /* Remove underline from links */
    color: white; /* Ensure text color remains white */
    display: block; /* Ensure the box acts like a block element */
  }
  
  .experience-box:hover {
    transform: scale(1.05);
    background-color: #333;
  }
  
  .experience-date {
    color: #aaa;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  
  .experience-content {
    margin-left: 10px;
  }
  
  .experience-title {
    font-size: 1.2rem; /* Smaller title size */
    font-weight: bold;
    margin: 5px 0;
  }
  
  .experience-company {
    font-size: 1rem; /* Smaller font size for the company name */
    font-style: italic; /* Optional: Italicize the company name */
    margin-bottom: 20px;
    margin: 5px 0;
    color: #ccc;
  }
  
  .experience-description {
    margin: 10px 0;
  }
  
  .experience-skills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .skill-oval {
    background-color: #555;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .experience-box {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }