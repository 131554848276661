/* src/components/ContactMe.css */

.contact-section {
    background-color: black;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
  }
  
  .contact-buttons {
    display: flex;
    gap: 20px; /* Space between buttons */
    margin: 20px;
  }
  
  .contact-button {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon and text */
    padding: 10px 20px;
    background-color: #222;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: transform 0.2s ease, background-color 0.3s ease;
    transform: scale(1); /* Default scale */
  }
  
  .contact-button:hover {
    transform: scale(1.1); /* Grow on hover */
    background-color: #333;
  }
  
  .contact-button:active {
    transform: scale(0.95); /* Shrink on click */
  }
  
  .contact-button span {
    font-size: 1.2rem;
  }

  .contact-header{
    font-size: 2rem;
  }
  