/* src/App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: black;
}

.glow-circle {
  position: absolute;
  width: 600px; /* Larger radius */
  height: 600px; /* Larger radius */
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%); /* Fading glow */
  border-radius: 50%;
  pointer-events: none; /* Prevent this element from blocking mouse events */
  transform: translate(-50%, -50%);

}

html {
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

/* Global section styling */
.section {
  text-align: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

h1, h2, p {
  margin: 20px 0;
}
