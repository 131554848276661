/* src/components/About.css */

.about-section {
    margin-top: 0;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }
  
  .about-content {
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  .hero-img {
    margin-right:50px;
    background-image: url('./profile.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: 250px;
    width: 250px;
    position: relative;
    -webkit-animation: morph 8s ease-in-out infinite;
    animation: morph 8s ease-in-out infinite;
    transition: all 1s ease-in-out;
    border-color: white;
    border: 10px;
    background-size: 85%;
  }
  
  @keyframes morph {
    0% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
    50% {
      border-radius: 50% 60% 40% 60% / 50% 60% 40% 60%;
    }
    100% {
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
  }
  
  .about-text h1 {
    font-size: 3rem;
    margin: 10px 0;
  }
  
  .about-text p {
    margin: 10px 0;
  }
  